import React from 'react';
import { Button, Pagination, Table } from 'antd';
import './style.scss'
import en from '../../localization/en';
import ar from '../../localization/ar';
import { useSelector } from 'react-redux';

export const TableGrid = ({
    columns,
    dataSource,
    onChange,
    pdfData,
    title,
    expandable,
    rowKey,
    loading,
    TableRef,
    pageSize,
    total,
    pdfTableColumns,
    handleTableChange
}) => {
    const { currentLang } = useSelector((state) => state?.user);
    const t = currentLang === "en" ? en : ar;

    function itemRender(current, type, originalElement) {
        if (type === 'prev')
            return <a>{t.Previous}</a>;

        if (type === 'next')
            return <a> {t.Next} </a>;

        return originalElement;
    }

    return (<>
        <div className='table__wrapper' >
            <div className='table__wrapper--header'>
                {title}
            </div>
            <div ref={TableRef} className='table__container'>
                <Table
                    rowKey={rowKey}
                    expandable={expandable}
                    columns={columns}
                    dataSource={dataSource}
                    onChange={handleTableChange}
                    pagination={{ position: ['none', 'none'], pageSize: pageSize ? pageSize : 30 }}
                    pageSize={pageSize ? pageSize : 30}
                    loading={loading}
                />
                <Pagination
                    pageSize={pageSize ? pageSize : 30}
                    total={total || dataSource?.length}
                    onChange={(page) => {
                        let paging = {
                            current: page
                        }
                        onChange(paging)
                    }}
                    itemRender={itemRender} />
            </div>
        </div >

        <div className='table__wrapper' id="print-section" style={{ display: 'none' }} >
            <div ref={TableRef} className='table__container'>
                <Table
                    style={{ width: '100%', minWidth: 'unset' }}
                    rowKey={rowKey}
                    expandable={expandable}
                    columns={pdfTableColumns ? pdfTableColumns : columns}
                    dataSource={pdfData ? pdfData : dataSource}
                    onChange={handleTableChange}
                    pagination={{ position: ['none', 'none'], pageSize: pageSize ? pageSize : 30 }}
                    pageSize={pageSize ? pageSize : 30}
                    loading={loading}
                />
            </div>
        </div>
    </>
    );
};
