import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { TableGrid } from '../../../Component/Table';
// import './style.scss' 
import Api from '../../../Network/ApiConfig'

const style = {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000',
    margin: '30px 15px 22px 15px'
}

export const MyHistorySub = () => {
    const [historySubList, setHistorySubList] = useState([]);

    const [ordersListPaginate, setHistorySubListPaginate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);

    const fetchOrderList = (url) => {
        let body = {
            pagination_number: 10
        }
        setLoading(true)
        Api.post(url ? url : 'subscriptions/old/history', body).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0])
            }
            if (res.data.code === 200) {
                setHistorySubListPaginate(res.data.data)
                setHistorySubList(res.data.data.supplier_subscription_history)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchOrderList();
    }, []);

    const columns = [
        {
            title: <p className='table-header-text'> START Date <span>تاريخ البدء</span> </p>,
            dataIndex: 'start_date',
        },
        {
            title: <p className='table-header-text'> End date <span> تاريخ الانتهاء </span></p>,
            dataIndex: 'end_ate',
        },
        {
            title: <p className='table-header-text'> dURATION <span> المدة </span></p>,
            dataIndex: 'duration',
        },
        {
            title: <p className='table-header-text'> Total  <span> الاجمالي </span></p>,
            dataIndex: 'price',
        },
    ];

    return (
        <div className='order__wrapper'>

            <div className='table__filter--mySub'>
                <p style={style}> Subscription History <span> تاريخ الاشتراكات </span></p>
            </div>
            <TableGrid
                columns={columns}
                loading={loading}
                dataSource={historySubList}
                defaultCurrent={10}
                pagination={{ pageSize: defaultCurrentVal, total: ordersListPaginate?.pagination?.total }}
                total={ordersListPaginate?.pagination?.total}
                onChange={(pagination) => {
                    let url = `orders?page=${pagination?.current}`
                    fetchOrderList(url)
                }}
                pageSize={defaultCurrentVal}
            />
        </div>
    );
};
