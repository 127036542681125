import { Form, Input, Button, message } from "antd";
import { useEffect } from "react";
import Api from "../../../Network/ApiConfig";
import "./style.scss";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";
export const PersonalInfo = () => {
  const [form] = Form.useForm();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  const supplierInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const handleFetchProfileData = () => {
  //   Api.get('profile').then(res => {
  //     if (res.data.validation.length > 0) {
  //       message.error(res.data.validation[0])
  //     }
  //     if (res.data.code === 200) {
  //       const data = res.data.data.profile;
  //       form.setFieldsValue({
  //         username: data.name,
  //         email: data.email,
  //         phone: data.phone,
  //         // Password
  //       });
  //     }

  //   })
  // }

  // useEffect(() => handleFetchProfileData(), [])

  const handleChangeData = (values) => {
    return false;
    let body = {
      name: values.username,
      email: values.email,
      phone: values.phone,
      password: values.password,
    };

    Api.post("profile/personal", body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      } else {
        message.success("Profile updated successfully");
        // handleFetchProfileData()
      }
    });
  };

  return (
    <div className="personal__info">
      {/* <p>يمكنك تعديل بيانات الحساب</p> */}

      <Form
        form={form}
        initialValues={{
          username: supplierInfo?.FullName,
          email: supplierInfo?.Email,
          phone: supplierInfo?.PhoneKey + supplierInfo?.PhoneNumber,
        }}
        name="personalInfo"
        className="form__wrapper"
        onFinish={handleChangeData}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label={t.CompanyName}
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label={t.EmailAddress}
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your Email!",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        {/* <Form.Item
          label="الهاتف"
          name="phone"
          rules={[{ required: true, message: 'Please input your Phone!' }]}
          >
          <Input type="number" />
        </Form.Item> */}

        <Form.Item
          label={t.Phonenumber}
          name="phone"
          rules={[
            {
              required: true,
              // message: `${t.Pleaseinput} ${t.Phonenumber}`,
            },
          ]}
          className="input-holder"
        >
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="AE"
            disabled
          />
        </Form.Item>

        {/* <Form.Item
          label="كلمة السر"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item> */}

        {/* <Form.Item>
          <Button className='btn__profile' type="primary" htmlType="submit">
            {t.Save}
            <span>&#10004;</span>
          </Button>
        </Form.Item> */}
      </Form>
    </div>
  );
};
