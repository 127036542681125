import axios from "axios";

const url = "https://chauffeur-be-staging-j2ezitroia-uc.a.run.app/api/";

export const userLogin = (body, onSucess, onFail) => {
  axios({
    method: "post",
    url: `${url}Companies/CompanyLogin`,
    data: body,
    headers: { "Content-Type": "application/json;" },
  })
    .then((response) => onSucess(response))
    .catch((err) => onFail(err));
};
