import styles from "./order-details.module.scss";
import cls from "classnames";
import {
  Link,
  withRouter,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom";
import { Avatar, Divider, Image, Modal, Rate, Spin } from "antd";
import { Button, Col, message, Row, Space } from "antd";
import { useEffect, useState } from "react";
import moment from "moment";
import img_1 from "../../images/ic_current.png";
import img_2 from "../../images/Line 2.png";
import img_5 from "../../images/Group 59644.svg";
import img_6 from "../../images/Group 59645.svg";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Api from "../../Network/ApiConfig";
import { useSelector } from "react-redux";
import en from "../../localization/en";
import ar from "../../localization/ar";
import dayjs from "dayjs";

function OrderInfo() {
  const [OrderDetails, setOrderDetails] = useState(null);
  const [loading, setloading] = useState(false);
  const params = useParams();
  const history = useHistory();
  const { confirm } = Modal;
  const location = useLocation();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  useEffect(() => {
    setloading(true);
    setOrderDetails(location?.state);
    Api.get(`Companies/GetOrderDetails?OrderId=${params?.id}`).then(
      (res) => {
        setloading(false);
        setOrderDetails(res?.data?.model);
      },
      (err) => {
        setloading(false);
        message.error(err?.message);
      }
    );
  }, [currentLang]);

  const handelCancelOrder = () => {
    const { Id } = OrderDetails;
    confirm({
      title: "? هل تريد الغاء هذا الاوردر",
      icon: <ExclamationCircleOutlined />,
      okText: "حسنا",
      cancelText: "الغاء",
      onOk: () => {
        Api.get(`Orders/UpdateOrderStatus?OrderId=${Id}&OrderStatusId=3`).then(
          (res) => {
            if (res?.status === 200) {
              message.success(res?.data?.metas?.message);
              history.push("/orders");
            }
          },
          (err) => {
            message.error(err?.message);
          }
        );
      },
      onCancel: () => {},
    });
  };

  return (
    <Spin spinning={loading} size="large">
      <div className={styles.order_details_page}>
        <div className="container">
          <div className={styles.order_details_Holder}>
            {/* Location info */}
            <div className={styles.section}>
              <h4 className={styles.section_title}>{t.locationInfo}</h4>
              <div className={cls(styles.section_body, styles.pad_less)}>
                <div className={styles.order}>
                  <div className={styles.order_header}>
                    <h4>
                      {t.deliveryService} <span>(#{OrderDetails?.Id})</span>
                    </h4>
                    <div className={styles.order_price}>
                      <span>
                        {OrderDetails?.Total} {t.SAR}
                      </span>
                    </div>
                  </div>

                  <div className={styles.order_location}>
                    <div className={styles.location_diagram}>
                      <div className={styles.logo_holder}>
                        <Image src={img_1} alt="img" preview={false} />
                      </div>
                      <div className={styles.line_holder}>
                        <Image
                          src={img_2}
                          width={2}
                          alt="img"
                          preview={false}
                        />
                      </div>
                      <div className={styles.logo_holder}>
                        <Image src={img_1} alt="img" preview={false} />
                      </div>
                    </div>
                    <div className={styles.location_text}>
                      <div>
                        <h5>{t.from}</h5>
                        <p>{OrderDetails?.FromCustomerAddress?.Caption}</p>
                      </div>
                      <div>
                        <h5>{t.to}</h5>
                        <p>{OrderDetails?.ToCustomerAddress?.Caption}</p>
                      </div>
                    </div>
                  </div>
                  <p className={styles.order_date}>
                    {/* {moment(OrderDetails?.creationDate).format(
                      "DD-MM-YYYY , hh:mm a"
                    )} */}
                    <p>
                      {/* {moment
                        .utc(OrderDetails?.CreationDate)
                        .local()
                        .format("YYYY/M/D - HH:MM")} */}
                      {dayjs
                        .utc(OrderDetails?.CreationDate)
                        .local()
                        .format("YYYY-MM-DD")}
                    </p>
                  </p>
                </div>
              </div>
            </div>
            {/* Delivery information */}
            {OrderDetails?.Delivery && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>{t.DriverInformation}</h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_info}>
                    <Row
                      gutter={[30, 30]}
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <div className={styles.info}>
                          {/* <Image
                          src={""}
                          alt="img"
                          width={100}
                          preview={false}
                        /> */}
                          <Avatar
                            size={40}
                            src={OrderDetails?.Delivery?.Image}
                            style={{ marginRight: 10 }}
                          />
                          <Space direction="vertical">
                            <h5>
                              {currentLang === "en"
                                ? OrderDetails?.Delivery?.Name
                                : OrderDetails?.Delivery?.NameLT}
                            </h5>
                            <p>{t.delivery}</p>
                          </Space>
                        </div>
                      </Col>
                      <Col>
                        <div className={styles.actions}>
                          {/* <Link to="">
                          <a>
                            <Image src={img_5} alt="img" preview={false} />
                          </a>
                        </Link> */}
                          <a
                            href={`tel:${OrderDetails?.Delivery?.AspNetUsers?.PhoneKey}${OrderDetails?.Delivery?.AspNetUsers?.PhoneNumber}`}
                          >
                            <Image src={img_6} alt="img" preview={false} />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {/* Customer information */}
            {OrderDetails?.Customer && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>
                  {t.CustomerInformation}
                </h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_info}>
                    <Row
                      gutter={[30, 30]}
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <div className={styles.info}>
                          {/* <Image
                          src={""}
                          alt="img"
                          width={100}
                          preview={false}
                        /> */}
                          <Avatar
                            size={40}
                            src={
                              OrderDetails?.Customer?.AspNetUsers?.ProfileImage
                            }
                            style={{ marginRight: 10 }}
                          />
                          <Space direction="vertical">
                            <h5>
                              {currentLang === "en"
                                ? OrderDetails?.Customer?.AspNetUsers?.FullName
                                : OrderDetails?.Customer?.AspNetUsers?.FullName}
                            </h5>
                            {/* <p>{t.delivery}</p> */}
                          </Space>
                        </div>
                      </Col>
                      <Col>
                        <div className={styles.actions}>
                          {/* <Link to="">
                          <a>
                            <Image src={img_5} alt="img" preview={false} />
                          </a>
                        </Link> */}
                          <a
                            href={`tel:${OrderDetails?.Customer?.AspNetUsers?.PhoneKey}${OrderDetails?.Customer?.AspNetUsers?.PhoneNumber}`}
                          >
                            <Image src={img_6} alt="img" preview={false} />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {/* Receiver information */}
            {OrderDetails?.Receiver && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>
                  {t.ReceiverInformation}
                </h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_info}>
                    <Row
                      gutter={[30, 30]}
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <div className={styles.info}>
                          {/* <Image
                          src={""}
                          alt="img"
                          width={100}
                          preview={false}
                        /> */}
                          <Avatar
                            size={40}
                            src={
                              OrderDetails?.Receiver?.AspNetUsers?.ProfileImage
                            }
                            style={{ marginRight: 10 }}
                          />
                          <Space direction="vertical">
                            <h5>
                              {currentLang === "en"
                                ? OrderDetails?.Receiver?.AspNetUsers?.FullName
                                : OrderDetails?.Receiver?.AspNetUsers?.FullName}
                            </h5>
                            {/* <p>{t.delivery}</p> */}
                          </Space>
                        </div>
                      </Col>
                      <Col>
                        <div className={styles.actions}>
                          {/* <Link to="">
                          <a>
                            <Image src={img_5} alt="img" preview={false} />
                          </a>
                        </Link> */}
                          <a
                            href={`tel:${OrderDetails?.Receiver?.AspNetUsers?.PhoneKey}${OrderDetails?.Receiver?.AspNetUsers?.PhoneNumber}`}
                          >
                            <Image src={img_6} alt="img" preview={false} />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {!OrderDetails?.Receiver && OrderDetails?.ToCustomerAddress && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>
                  {t.ReceiverInformation}
                </h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_info}>
                    <Row
                      gutter={[30, 30]}
                      justify="space-between"
                      align="middle"
                    >
                      <Col>
                        <div className={styles.info}>
                          {/* <Image
                          src={""}
                          alt="img"
                          width={100}
                          preview={false}
                        /> */}
                          <Avatar
                            size={40}
                            src={
                              OrderDetails?.Receiver?.AspNetUsers?.ProfileImage
                            }
                            style={{ marginRight: 10 }}
                          />
                          <Space direction="vertical">
                            <h5>{OrderDetails?.ToCustomerAddress?.Title}</h5>
                            {/* <p>{t.delivery}</p> */}
                          </Space>
                        </div>
                      </Col>
                      <Col>
                        <div className={styles.actions}>
                          {/* <Link to="">
                          <a>
                            <Image src={img_5} alt="img" preview={false} />
                          </a>
                        </Link> */}
                          <a
                            href={`tel:${OrderDetails?.Receiver?.AspNetUsers?.PhoneKey}${OrderDetails?.ToCustomerAddress?.PhoneNumber}`}
                          >
                            <Image src={img_6} alt="img" preview={false} />
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            )}
            {(OrderDetails?.IsCustomerRated ||
              OrderDetails?.IsDeliveryRate) && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>{t.orderRate}</h4>
                <div className={styles.section_body}>
                  <div className={styles.order_info}>
                    {/* <p>
                    {moment(OrderDetails?.creationDate).format(
                      "DD-MM-YYYY , hh:mm a"
                    )}
                  </p> */}
                    {OrderDetails?.IsCustomerRated && (
                      <>
                        <Row
                          gutter={[40, 20]}
                          justify="space-between"
                          align="middle"
                          style={{ marginBottom: "2rem" }}
                        >
                          <Col>
                            <span className={styles.head}>
                              {t.orderRateToDriver}
                            </span>
                          </Col>
                          <Col>
                            <Rate
                              disabled
                              allowHalf
                              value={OrderDetails?.CustomerRate?.RateValue}
                            />
                          </Col>
                        </Row>
                        <p
                          style={{ marginBottom: "4rem" }}
                          className="rete-desc"
                        >
                          {OrderDetails?.CustomerRate?.Comment}
                        </p>
                      </>
                    )}
                    {OrderDetails?.IsDeliveryRate && (
                      <>
                        <Row
                          gutter={[40, 20]}
                          justify="space-between"
                          align="middle"
                          style={{ marginBottom: "2rem" }}
                        >
                          <Col>
                            <span className={styles.head}>
                              {t.orderRateToCustomer}
                            </span>
                          </Col>
                          <Col>
                            <Rate
                              disabled
                              allowHalf
                              value={OrderDetails?.DeliveryRate?.RateValue}
                            />
                          </Col>
                        </Row>
                        <p
                          style={{ marginBottom: "4rem" }}
                          className="rete-desc"
                        >
                          {OrderDetails?.DeliveryRate?.Comment}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* order info */}
            <div className={styles.section}>
              <h4 className={styles.section_title}>{t.orderInfo}</h4>
              <div className={styles.section_body}>
                <div className={styles.order_info}>
                  {/* <Row
                    gutter={[40, 20]}
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "8rem" }}
                  >
                    <Col>
                      <span className={styles.head}>{t.ServiceType}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {currentLang === "en"
                          ? OrderDetails?.PackgeSize?.Name
                          : OrderDetails?.PackgeSize?.NameLT}
                      </span>
                    </Col>
                  </Row> */}
                  <Row
                    gutter={[40, 20]}
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "8rem" }}
                  >
                    <Col>
                      <span className={styles.head}>{t.packageSize}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {currentLang === "en"
                          ? OrderDetails?.PackgeSize?.Name
                          : OrderDetails?.PackgeSize?.NameLT}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    gutter={[40, 20]}
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "8rem" }}
                  >
                    <Col>
                      <span className={styles.head}>{t.deliveryType}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {currentLang === "en"
                          ? OrderDetails?.ServiceDeliveryType?.DeliveryType
                              ?.Name
                          : OrderDetails?.ServiceDeliveryType?.DeliveryType
                              ?.NameLT}
                      </span>
                    </Col>
                  </Row>
                  <Row gutter={[30, 20]} justify="space-between" align="middle">
                    <Col>
                      <span className={styles.head}>{t.categoryType}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {currentLang === "en"
                          ? OrderDetails?.Category?.Name
                          : OrderDetails?.Category?.NameLT}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {/* order info */}
            {OrderDetails?.OrderItems?.length > 0 && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>{t.products}</h4>
                <div className={styles.section_body}>
                  <div className={styles.order_info}>
                    {OrderDetails?.OrderItems?.map((s) => (
                      <Row
                        key={s?.id}
                        gutter={[40, 20]}
                        justify="space-between"
                        align="middle"
                        style={{ marginBottom: "8rem" }}
                      >
                        <Col>
                          <span className={styles.head}>{s?.Name}</span>
                        </Col>
                        <Col>
                          <span className={styles.desc}>{s?.Count} X </span>
                          <Divider type="vertical" />
                          <span className={styles.desc}>
                            {s?.Price} {t.SAR}{" "}
                          </span>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {/* Delivery Time */}
            <div className={styles.section}>
              <h4 className={styles.section_title}>{t.deliveryDate}</h4>
              <div className={styles.section_body}>
                <div className={styles.delivary_time}>
                  <p>
                    {dayjs
                      .utc(OrderDetails?.DeliveryDateTime)
                      .local()
                      .format("YYYY-MM-DD")}
                  </p>
                </div>
              </div>
            </div>
            {/* Delivery Notes */}
            {OrderDetails?.DeliveryNote && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>{t.deliveryNotes}</h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_notes}>
                    <p>{OrderDetails?.DeliveryNote}</p>
                  </div>
                </div>
              </div>
            )}
            {OrderDetails?.CancelationReason && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>{t.CancelationReason}</h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_notes}>
                    <p>{OrderDetails?.CancelationReason}</p>
                  </div>
                </div>
              </div>
            )}
            {OrderDetails?.ResetImage && (
              <div className={styles.section}>
                <h4 className={styles.section_title}>{t.ReceiptImage}</h4>
                <div className={styles.section_body}>
                  <div className={styles.delivary_notes}>
                    <Image width={200} src={OrderDetails?.ResetImage} />
                  </div>
                </div>
              </div>
            )}
            {/* Delivery Cost */}
            <div className={styles.section}>
              <h4 className={styles.section_title}>{t.deliveryCost}</h4>
              <div className={styles.section_body}>
                <div className={styles.delivery_cost}>
                  <Row
                    gutter={[40, 20]}
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "8rem" }}
                  >
                    <Col>
                      <span className={styles.head}>{t.TotalPurchases}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {OrderDetails?.DeliveryCost} {t.SAR}
                      </span>
                    </Col>
                  </Row>
                  {OrderDetails?.SubTotal ? (
                    <Row
                      gutter={[40, 20]}
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: "8rem" }}
                    >
                      <Col>
                        <span className={styles.head}>{t.subTotal}</span>
                      </Col>
                      <Col>
                        <span className={styles.desc}>
                          {OrderDetails?.SubTotal} {t.SAR}
                        </span>
                      </Col>
                    </Row>
                  ) : null}
                  <Row
                    gutter={[40, 20]}
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "8rem" }}
                  >
                    <Col>
                      <span className={styles.head}>{t.Discount}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {OrderDetails?.DiscountAmount} {t.SAR}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    gutter={[40, 20]}
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "8rem" }}
                  >
                    <Col>
                      <span className={styles.head}>{t.VAT}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {OrderDetails?.VAT} {t.SAR}
                      </span>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={[30, 20]} justify="space-between" align="middle">
                    <Col>
                      <span className={styles.head}>{t.total}</span>
                    </Col>
                    <Col>
                      <span className={styles.desc}>
                        {OrderDetails?.Total} {t.SAR}
                      </span>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            {/* <div className={styles.box_btns}>
              <Button
                type="primary"
                className={cls("btn-primary fw", styles.btn_cancel)}
                onClick={handelCancelOrder}
              >
                cancel
              </Button>
              <Button
                type="primary"
                className={cls("btn-primary fw", styles.btn_next)}
              >
                Track Delivery
              </Button>
            </div> */}
            {/* end */}
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default OrderInfo;
