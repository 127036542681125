import React, { useEffect, useState } from "react";
import { Button, Select, Form, message, Input, Upload, Divider } from "antd";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UploadOutlined } from "@ant-design/icons";

import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const { Option } = Select;

export const AddOffer = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const supplierInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { currentUser } = useSelector((state) => state?.user);
  const [selectedItems, setSelectedItems] = useState([]);
  const { PackgeSizes, DeliveryTypes, Cities, Services } = useSelector(
    (state) => state?.user?.metaData
  );
  const location = useLocation();
  const { currentLang } = useSelector((state) => state?.user);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const handleServiceChange = (value) => {
    setSelectedServiceId(value);
  };

  const isPersonalShopper = (serviceId) => {
    const service = Services?.find((s) => s.Id === serviceId);
    return service?.Name === "Personal shopper"; // Adjust this condition based on your data structure
  };

  const t = currentLang === "en" ? en : ar;

  useEffect(() => {
    setSelectedItems(Cities);
  }, []);

  const onFinish = (values) => {
    // return false;
    setLoading(true);
    const phoneNumber = parsePhoneNumber(values.PhoneNumber || "");

    let body = {
      Description: values?.Description,
      DescriptionLT: values?.DescriptionLT,
      Price: values?.Price,
      FromCityId: values?.FromCityId,
      ToCityId: values?.ToCityId,
      PackgeSizeId: values?.PackgeSizeId,
      ServiceId: values?.ServiceId,
      DeliveryTypeId: values?.DeliveryTypeId || 5,
    };
    if (location?.state) {
      body.Id = location?.state?.Id;
      Api.post("CompanyPackgeSizes/Update", body).then(
        (res) => {
          if (res?.Code) {
            message.error(res?.message);
            setLoading(false);
            return false;
          }
          setLoading(false);
          if (res?.status === 200) {
            history.push({
              pathname: "/offers",
            });
          }
        },
        (err) => {
          setLoading(false);
          message.error(err?.message);
        }
      );
      return false;
    }
    Api.post("CompanyPackgeSizes/Add", body).then(
      (res) => {
        if (res?.Code) {
          message.error(res?.message);
          setLoading(false);
          return false;
        }
        // message.success(res?.data?.model?.AspNetUsers);
        setLoading(false);
        if (res?.status === 200) {
          history.push({
            pathname: "/offers",
          });
        }
      },
      (err) => {
        setLoading(false);
        message.error(err?.message);
      }
    );
  };

  const handelFilterCity = (Id) => {
    const filterArr = Cities?.filter((o) => o?.Id !== Id);
    setSelectedItems(filterArr);
    // selectedItems?.filter((o) => !selectedItems?.includes(o));
  };

  return (
    <div className="content__wrapper">
      {/* <div className='assign__cate--header'>
                <p style={{ fontWeight: 'bold' }} className='inter'>You have: {categoryList?.length} categories</p>
            </div> */}
      <div className="product__wrapper--add">
        {location?.state ? (
          <p className="product__title--page">{t.UpdatePrice} </p>
        ) : (
          <p className="product__title--page">{t.AddPrice} </p>
        )}
        <Form
          form={form}
          initialValues={{
            Description: location?.state?.Description,
            DescriptionLT: location?.state?.DescriptionLT,
            Price: location?.state?.Price,
            FromCityId: location?.state?.FromCityId,
            ToCityId: location?.state?.ToCityId,
            PackgeSizeId: location?.state?.PackgeSizeId,
            ServiceId: location?.state?.ServiceId,
            DeliveryTypeId: location?.state?.DeliveryTypeId,
          }}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={t.descriptionAr}
            name="DescriptionLT"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t.descriptionEn}
            name="Description"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t.Deliveryfromthecity}
            name="FromCityId"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Select
            // onChange={handelFilterCity}
            >
              {selectedItems.map((item) => (
                <Select.Option key={item?.Id} value={+item?.Id}>
                  {currentLang === "en" ? item?.Name : item?.NameLT}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t.Deliverytothecity}
            name="ToCityId"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Select
            // onChange={handelFilterCity}
            >
              {selectedItems.map((item) => (
                <Select.Option key={item?.Id} value={+item?.Id}>
                  {currentLang === "en" ? item?.Name : item?.NameLT}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t.ServiceType}
            name="ServiceId"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Select onChange={handleServiceChange}>
              {Services?.filter((s) => s?.Enabled).map((service) => (
                <Option key={service.Id} value={service.Id}>
                  {currentLang === "en" ? service?.Name : service?.NameLT}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {!isPersonalShopper(selectedServiceId) && (
            <Form.Item
              label={t.DeliveryTypes}
              name="DeliveryTypeId"
              rules={[{ required: true, message: t.ThisFieldisrequired }]}
            >
              <Select>
                {DeliveryTypes?.filter((s) => s?.Id !== 5).map((delivery) => (
                  <Option key={delivery.Id} value={delivery.Id}>
                    {currentLang === "en" ? delivery?.Name : delivery?.NameLT}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label={t.PackgeSize}
            name="PackgeSizeId"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Select>
              {PackgeSizes.map((Package) => (
                <Option key={Package.Id} value={Package.Id}>
                  {currentLang === "en" ? Package?.Name : Package?.NameLT}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t.Price}
            name="Price"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexBasis: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexBasis: "100%",
              }}
            >
              <Button
                loading={loading}
                htmlType="submit"
                style={{ border: "none", color: "#fff" }}
              >
                {" "}
                {t.Save}{" "}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
