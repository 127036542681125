import { Layout, Menu, Dropdown, Progress, Avatar, Button, Badge } from 'antd';
import './style.scss'
import { DownOutlined, } from '@ant-design/icons';
import { Redirect, useHistory } from 'react-router-dom';

import notification_img from "../../../images/notification.svg";
import notification_bing_img from "../../../images/notification-bing.svg";
import { useDispatch, useSelector } from 'react-redux';
import Api from '../../../Network/ApiConfig';
import { setCurrentLang, setNotificationList } from '../../../redux/user/user.action';
import moment from 'moment';
import en from '../../../localization/en';
import ar from '../../../localization/ar';

const { Header } = Layout;

export const MainHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const supplierInfo = JSON.parse(localStorage.getItem('userInfo'))
  const { currentUser, notificationList, currentLang } = useSelector((state) => state?.user);

	const t = currentLang === "en" ? en : ar;
  const menu = (
    <Menu>
      <Menu.Item key="0" onClick={() => history.push('/profile/PersonalInfo')}>{t.MyProfile}</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1" onClick={() => {
        localStorage.removeItem("token");
        window.location.reload(false);
        <Redirect to={'/login'} />
      }}>{t.Logout}</Menu.Item>
    </Menu>
  );

  const chanageLang = (lang) => {
    dispatch(setCurrentLang(lang));
    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
  };

  return (
    <Header className="site-layout-background">

            <div className="lang-holder"
                    >
                      {currentLang === "ar" ? 
                      <p className="lang-select" onClick={ () => chanageLang("en")} >EN</p> : 
                      <p className="lang-select" onClick={ () => chanageLang("ar")}>العربيه</p>
                    }
              
            </div>

            <Dropdown
                          onClick={() => {
                            Api.get("Users/SeenNotification").then(
                              (res) => {
                                Api.get("Users/GetAllNotification?Page=0").then(
                                  (res) => {
                                    // dispatch(setNotificationList(res?.data?.model));  
                                    dispatch(setNotificationList(res?.data?.model || [])); 
                                  },
                                  (err) => {
                                    console.log(err);
                                  }
                                );
                              },
                              (err) => {
                                console.log(err);
                              }
                            );
                          }}
                trigger={["click"]}
                arrow
                overlay={
                  <div className="header_dropdown_User_actions">
                    <h4>{t.Notifications}</h4>
                    <Menu
                      className="user-dropdown-holder list"
                      // onClick={(key) => onClickMenu(key)}
                    >
                      { notificationList  && notificationList?.map(s => 
                      <Menu.Item
                        key={s?.Id}
                        icon={
                          <img
                            src={notification_bing_img}
                            className="icon"
                          />
                        }
                        onClick={() => {
                          if(s?.NotificationTypeId === 2){
                            history.push(`/ordersDetails/${s?.OrderId}`);
                          }
                        }}
                      >
                        <div className="item_content">
                          <p>
                          {currentLang === "en" ? s?.Description : s?.DescriptionLT }

                          </p>
                          <span>
                          {moment.utc(s?.CreationDate)
                                .local().fromNow()}
                          </span>
                        </div>
                      </Menu.Item>
                      )}
                    </Menu>
                  </div>
                }
              >
                <Badge count={notificationList?.filter(s => !s?.IsSeen)?.length || 0}>
                <Button
                  icon={<img src={notification_bing_img} />}
                  className="btn_notif"
                  />
                  </Badge>
              </Dropdown>

      <div className="header__right">
        <Dropdown overlay={menu} trigger={['click']}>
          <p className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {/* <img className="supplier_logo" src={supplierInfo?.ProfileImage} alt="logo" /> */}
            <Avatar src={supplierInfo?.ProfileImage} size={32} /> 
            {supplierInfo?.FullName} <DownOutlined />
          </p>
        </Dropdown>
      </div>



    </Header>
  );
}
