import React, { useEffect, useState } from 'react';
import Api from '../../../Network/ApiConfig';
import './style.scss'

export const MySubscription = () => {
  const [currentSubscription, setCurrentSubscription] = useState([])
  const handleFetchMySubscription = () => {
    Api.get('subscriptions/currentSubscription').then(res => {
      setCurrentSubscription(res.data.data.currentSubscription)
    })
  }

  useEffect(() => handleFetchMySubscription(), [])

  return (
    <div className='dashboard__wrapper'>
      <h2> Current Subscription - تفاصيل الاشتراك</h2>
      <div className='subscription__container'>
        <p>
          <img src="/images/subscription/package.svg" alt="" />
          الباقة - Package: <span> {currentSubscription.name}</span>
        </p>

        <p>
          <img src="/images/subscription/duration.svg" alt="" />
          المدة - (Month) Duration: <span>{currentSubscription.durationInMonth}</span>
        </p>

        <p>
          <img src="/images/subscription/rank.svg" alt="" />
          عدد نقاط الترتيب -  Ranking Points: <span>{currentSubscription.rankPoints}</span>
        </p>

        <p>
          <img src="/images/subscription/tender.svg" alt="" />
          نقاط اظهار المناقصات - Tender Unlocks: <span>{currentSubscription.tenders}</span>
        </p>

        <p>
          <img src="/images/subscription/date.svg" alt="" />
          تاريخ البداية - Start Date: <span>{currentSubscription.start_date}</span>
        </p>

        <p>
          <img src="/images/subscription/date.svg" alt="" />
          تاريخ النهاية - End Date: <span>{currentSubscription.end_date}</span>
        </p>

        <p>
          <img src="/images/subscription/promotion.svg" alt="" />
          نقاط العروض - Promotions: <span>{currentSubscription.promotions}</span>
        </p>

        <p>
          <img src="/images/subscription/clients.svg" alt="" />
          عدد العملاء - Clients: <span>{currentSubscription.clients}</span>
        </p>

        <p>
          <img src="/images/subscription/category.svg" alt="" />
          عدد الاقسام - No. of Category  : {currentSubscription.categories}
        </p>

        <p>
          <img src="/images/subscription/products.svg" alt="" />
          عدد المنتجات - No. of Products : <span>{currentSubscription.products}</span>
        </p>

        <p>
          <img src="/images/subscription/emails.svg" alt="" />
          حملات التسويق بالايميل - Mass Emails : <span>{currentSubscription.emails}</span>
        </p>

        <p>
          <img src="/images/subscription/google.svg" alt="" />
          Google Budget : <span>{currentSubscription.googleBudget}</span>
        </p>

        <p>
          <img src="/images/subscription/facebook.svg" alt="" />
          Facebook Budget : <span>{currentSubscription.facebookBudget}</span>
        </p>
      </div>
    </div>
  );
};
