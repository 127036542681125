import React from "react";
import styles from "./notification.module.scss";
import { Image } from "antd";
import img_1 from "../../images/notification-bing.svg";
import { useSelector } from "react-redux";
import moment from "moment";
 
function Notification() {
  const { currentUser, notificationList, currentLang } = useSelector((state) => state?.user);

  return (
    <>
      <div className={styles.notification_page}>
        <div className="container">
          <div className={styles.notification_page_holder}>
            <h2 className={styles.head_title}> {currentLang === "en" ? "Notification" : "إشعارات" }</h2>
            <ul className={styles.notification_list}>
            { notificationList  && notificationList?.map(s => 
              <li key={s?.Id} className={styles.notification_item}>
                <div>
                  <Image src={img_1} alt="img" preview={false} />
                </div>
                <div className={styles.item_content}>
                  <h4>                  {currentLang === "en" ? s?.NotificationType?.Name : s?.NotificationType?.NameLT }
</h4>
                  <p>
                  {currentLang === "en" ? s?.Description : s?.DescriptionLT }
                  </p>
                  <time> {moment.utc(s?.CreationDate)
                                .local().fromNow()}</time>
                </div>
              </li>
            )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
