import React, { useEffect, useState } from 'react';
import { TableGrid } from '../../../Component/Table';
import './style.scss'
import Api from '../../../Network/ApiConfig'
import { message } from 'antd';
import Input from 'antd/lib/input/Input';

export const UserGroup = () => {
  const [groupList, setGroupList] = useState([]);
  const [groupListFiltered, setGroupListFiltered] = useState([]);

  const fetchGroupList = (url) => {
    let body = {
      pagination_number: 10
    }

    Api.post(url ? url : 'groups/get', body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0])
      }
      if (res.data.code === 200) {
        setGroupList(res.data.data.my_groups);
        setGroupListFiltered(res.data.data);
      }
    })
  }

  useEffect(() => {
    fetchGroupList()
  }, []);

  const columns = [

    {
      title: <p className='table-header-text'> User group name <span> الاسم </span></p>,
      dataIndex: 'name',
    },
    {
      title: <p className='table-header-text'> Products <span> المنتجات </span></p>,
      dataIndex: 'products',
    },
    {
      title: <p className='table-header-text'> Minimum order amount <span> الحد الادني للطلب </span></p>,
      dataIndex: 'minimum_order_amount',
    },

  ];


  const handleFilterList = (value) => {
    if (!!value) {
      let salesFiltered = groupListFiltered?.my_groups?.filter(i => i.name.toLowerCase().includes(value.toLowerCase()));
      setGroupList(salesFiltered)
    } else {
      fetchGroupList()
    }
  }

  return (
    <div>
      <TableGrid title={
        <div className='table__filter' >
          <p className='bold'> User groups <span className='normal-font' style={{ marginLeft: '10px' }}> شريحة المشتريين </span></p>
          <div className='search__list--input'>
            <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
          </div>
        </div>
      }
        columns={columns}
        dataSource={groupList}
        pageSize={10}
        defaultCurrent={10}
        total={groupListFiltered?.pagination?.total}
        onChange={(pagination) => {
          let url = `groups/get?page=${pagination?.current}`
          fetchGroupList(url)
        }}
      />
    </div>
  );
};
