import React, { useEffect, useState } from "react";
import { Button, Modal, Input, Select, Form, Upload, message } from "antd";
import "./style.scss";
import Api from "../../../../Network/ApiConfig";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import ImgCrop from "antd-img-crop";
import {
  ExclamationCircleOutlined,
  FileImageOutlined,
} from "@ant-design/icons";

const { confirm } = Modal;
const { Option } = Select;

export const AddProduct = () => {
  const [form] = Form.useForm();
  const [groupList, setGroupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [unitsList, setUnitsList] = useState([]);
  const [productTags, setProductTags] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [imgList, setImgList] = useState([]);
  const [pdfList, setPdfList] = useState([]);
  const [groupListWithPrice, setGroupListWithPrice] = useState([]);
  const [priceValue, setPriceValue] = useState([]);
  const [videoLink, setVideoLink] = useState("");
  const [ProductSpec, setProductSpec] = useState({
    firstSpec: "",
    SecondSpec: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const history = useHistory();

  const location = useLocation();

  const fetchCategoriesList = () => {
    let body = {
      pagination_number: 100,
    };

    Api.post("categories/assigned", body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setCategoryList(res.data.data.categories);
      }
    });
  };

  const fetchGroupList = () => {
    let body = {
      pagination_number: 100,
    };
    Api.post("groups/get", body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        let data = [];
        res.data.data.my_groups.map((item) => {
          let itemData = item;
          item.group_price = 0;
          data.push(itemData);
        });
        if (!groupListWithPrice.length) setGroupListWithPrice(data);
        setGroupList(res.data.data.my_groups);
        if (location.state?.productId) {
          Api.get(`products/show?productId=${location.state.productId}`).then(
            (res) => {
              setGroupListWithPrice(res.data.data.product?.groups_prices);
              let tags = res.data.data.product?.keywords?.split(",");
              const localTags = tags?.map((tag) => {
                return { text: tag, id: tag };
              });

              setProductTags(localTags);
            }
          );
        }
      }
    });
  };

  const fetchMeasurementsUnitsList = () => {
    Api.get("products/unit/measurements").then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setUnitsList(res.data.data.unit_measurements);
      }
    });
  };

  useEffect(() => {
    fetchGroupList();
    fetchMeasurementsUnitsList();
    fetchCategoriesList();
  }, []);

  useEffect(() => {
    if (location.state?.productId) {
      Api.get(`products/show?productId=${location.state.productId}`).then(
        (res) => {
          const productData = res.data.data.product;

          form.setFieldsValue({
            categoryId: categoryList.find(
              (i) => i.name_en === productData.subCategoryEnName
            )?.id,
            video: productData?.video,
            name: productData?.name,
            unitId: unitsList.find(
              (i) => i.name === productData?.unit_measurement
            )?.id,
            price: productData?.price,
          });

          setPriceValue(productData?.price);

          const mainImg = {
            uid: "-1",
            name: productData?.mainImage.split("/")[
              productData?.mainImage.split("/").length - 1
            ],
            status: "done",
            url: productData?.mainImage,
            thumbUrl: productData?.mainImage,
            type: "main",
          };

          const images = productData?.product_images?.map((img, idx) => {
            return {
              uid: img.id,
              name: img?.image.split("/")[img?.image.split("/").length - 1],
              status: "done",
              url: img?.image,
              thumbUrl: img?.image,
            };
          });

          const descriptionValue = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(`<p>${productData.description}</p>`)
            )
          );

          setProductSpec({
            firstSpec: Object.keys(productData?.product_specs[0])[0],
            SecondSpec: Object.keys(productData?.product_specs[0])[0],
          });

          setImgList([mainImg, ...images]);
          setVideoLink(productData?.video);
          setEditorState(descriptionValue);
          setGroupList(productData?.groups_prices);
        }
      );
    }
  }, [categoryList, unitsList]);

  const handleChangeData = (values) => {
    let header = {
      "Content-Type": "application/json;",
    };

    if (!!!editorState.getCurrentContent().getPlainText()) {
      message.error("Description is Required");
      return false;
    }

    setBtnLoading(true);
    let groupListData = [];

    groupList.map((item) => {
      groupListData.push({
        user_group_id: item.id,
        group_price: item.group_price,
      });
    });

    let tags = [];
    productTags.map((tag) => {
      tags.push(tag.text);
    });
    let taaags = tags.join(",");
    let body = new FormData();
    body.append("categoryId", values.categoryId);

    body.append(`keywords`, taaags);
    body.append("description", editorState.getCurrentContent().getPlainText());
    body.append("name", values.name);
    body.append("price", priceValue);
    if (!!videoLink) body.append("video", videoLink);
    body.append("availability", 1);
    groupListWithPrice.map((item, idx) => {
      body.append(
        `user_group_id[${idx}]`,
        location.state?.productId ? item.group_id : item.id
      );
      body.append(`group_price[${idx}]`, item.group_price);
    });
    body.append(`product_spec_name[0]`, ProductSpec.firstSpec);
    body.append(`product_spec_value[0]`, ProductSpec.firstSpec);
    body.append(`product_spec_name[1]`, ProductSpec.SecondSpec);
    body.append(`product_spec_value[1]`, ProductSpec.SecondSpec);
    body.append("page_title", `${values.name} Title`);
    body.append("unitMeasurementId", values.unitId);
    if (pdfList.length) body.append("pdfFile", pdfList[0]);

    if (imgList.length) {
      if (!location.state) {
        body.append("mainImage", imgList[0].originFileObj);
        imgList.slice(1).map((img, idx) => {
          body.append("images[]", img.originFileObj);
        });
      }
    }

    if (imgList.length) {
      if (location.state?.productId) {
        body.append("productId", location.state.productId);
        Api.post("products/update", body).then((res) => {
          setBtnLoading(false);
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0]);
            setBtnLoading(false);
          } else {
            message.success(res.data.message || "successfully");
            setBtnLoading(false);
            history.push("/profile/all-product");
          }
        });
      } else {
        Api.post("products/store", body, header).then((res) => {
          setBtnLoading(false);
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0]);
            setBtnLoading(false);
          } else {
            message.success(res.data.message || "successfully");
            setBtnLoading(false);
            history.push("/profile/all-product");
          }
        });
      }
    } else {
      message.error("you have to add at least 1 images");
      setBtnLoading(false);
    }
  };

  const handleDelete = (i) =>
    setProductTags(productTags.filter((tag, index) => index !== i));

  const handleAddition = (tag) => setProductTags([...productTags, tag]);

  const onEditorStateChange = (editorState) => setEditorState(editorState);

  const handleChange = async ({ fileList }) => setImgList(fileList);

  const handleAddNewImages = (file) => {
    if (location.state?.productId) {
      let body = new FormData();
      body.append("productId", location.state?.productId);
      body.append("images[]", file);

      Api.post("products/add/images", body).then((res) => {
        if (res.data.validation.length > 0)
          message.error(res.data.validation[0]);

        if (res.data.code === 200)
          message.success(res.data.message || "successfully");
      });
    }
  };

  const uploadButton = (
    <p className="image__upload--btn">
      <FileImageOutlined />{" "}
    </p>
  );

  const handleUserGroupPrice = (value, idx) => {
    let groupListData = [...groupListWithPrice];
    groupListData[idx].group_price = value;
    setGroupListWithPrice(groupListData);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onRemove = (file) => {
    if (location.state) {
      let body = new FormData();
      body.append("productImageId", +file.uid);
      confirm({
        title: `Delete This Product?`,
        icon: <ExclamationCircleOutlined />,
        okText: "Delete",
        cancelText: "Cancel",
        onOk: () => {
          Api.post("products/delete/image", body).then((res) => {
            if (res.data.validation.length > 0) {
              message.error(res.data.validation[0]);
            }
            if (res.data.code === 200) {
              message.success(res.data.message || "successfully");
              setImgList(imgList.filter((i) => i.uid !== file.uid));
            }
          });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    }
  };

  return (
    <div className="content__wrapper">
      <div className="product__wrapper">
        <div className="product__wrapper--header">
          <p>المنتجات: 10</p>
          <p>العروض: 20</p>
        </div>
      </div>
      <div className="product__wrapper--add">
        <p className="product__title--page">
          Add New Product - اضافة منتج جديد
        </p>
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={handleChangeData}
          // onFinishFailed={
          //     document
          //         .querySelector('.ant-form-item-has-error')
          //         ?.scrollIntoView(false)}
          autoComplete="off"
        >
          <Form.Item
            label="اضف صورة للمنتج )يمكنك اضافة اكثر من صورة ) "
            style={{ flexBasis: "100%" }}
            name="imgs"
          >
            <ImgCrop quality={0.1}>
              <Upload
                action={(file) => {
                  handleAddNewImages(file);
                }}
                accept="image/png, image/gif, image/jpeg"
                listType="picture-card"
                fileList={imgList}
                onChange={handleChange}
                onPreview={onPreview}
                onRemove={(file) =>
                  file.type === "main" ? false : onRemove(file)
                }
              >
                {imgList.length >= 8 ? null : uploadButton}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item
            label="اسم المنتج"
            name="name"
            rules={[{ required: true, message: "This Field is required!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="وحدة بيع المنتج"
            name="unitId"
            rules={[{ required: true, message: "This Field is required!" }]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {unitsList.map((group, idx) => (
                <Option key={idx} title={group.name} value={group.id}>
                  {group.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="* سعر المنتج" name="price" className="price__item">
            <span className="price__label"> السعر الاساسي *</span>
            <Input
              value={priceValue}
              onChange={(e) => setPriceValue(e.target.value)}
            />
          </Form.Item>

          <div className="choose__group">
            <div
              className={`choose__group--container ${
                isCollapsed ? "collapsed" : ""
              }`}
            >
              <p>
                سعر المنتج لكل شريحة المستخدمين
                <span
                  style={{ transform: isCollapsed ? "rotate(180deg)" : "none" }}
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  {" "}
                  <img src="/images/downArrow.svg" alt="Collapse" />{" "}
                </span>
              </p>

              {groupListWithPrice.map((group, idx) => (
                <Form.Item
                  label={
                    location.state?.productId ? group.group_name : group.name
                  }
                  name={location.state?.productId ? group.group_id : group.id}
                  className="price__item"
                >
                  <Input
                    type="number"
                    value={group.group_price}
                    onChange={(e) => handleUserGroupPrice(e.target.value, idx)}
                  />
                  <span className="price__label">جنيه</span>
                </Form.Item>
              ))}
            </div>
            {/* <Option key={idx} title={group.name} value={group.id} /> */}
          </div>

          <Form.Item
            label="اختار نوع قسم المنتج"
            name="categoryId"
            rules={[{ required: true, message: "This Field is required!" }]}
          >
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
            >
              {categoryList.map((group, idx) => (
                <Option
                  key={idx}
                  title={`${group.name_en} - ${group.name_ar}`}
                  value={group.id}
                >
                  {group.name_en} - {group.name_ar}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="كلمات البحث عن المنتج"
            style={{ flexBasis: "100%" }}
          >
            <div className="product__tags">
              {/* <ReactTags
                tags={productTags}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                placeholder=''
                autofocus={false}
              /> */}
            </div>
          </Form.Item>

          <Form.Item
            label="لينك فيديو عن المنتج"
            name="video"
            className="price__item"
            style={{ flexBasis: "100%" }}
            rules={[{ message: "This Field is required!" }]}
          >
            <span className="price__label">
              <img src="/images/video.svg" alt="video" />
            </span>
            <Input
              style={{ maxWidth: "300px" }}
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
              placeholder="Enter video link ... youtube, Vimeo or Vevo"
            />
          </Form.Item>

          <Form.Item
            label="ملف تفاصيل PDF"
            rules={[{ required: true, message: "This Field is required!" }]}
            className="rtl-label"
          >
            <Upload
              action={(file) => setPdfList([...pdfList, file])}
              fileList={pdfList}
              accept=".pdf"
            >
              <p className="upload__pdf" style={{ direction: "ltr" }}>
                ارفق ملف PDF
              </p>
            </Upload>
          </Form.Item>

          <Form.Item
            label=" * معلومات عن المنتج"
            name="video"
            className="price__item"
          >
            <Input
              value={ProductSpec.firstSpec}
              onChange={(e) =>
                setProductSpec({ ...ProductSpec, firstSpec: e.target.value })
              }
              style={{ width: "150px", margin: "0 4px" }}
            />
            <Input
              value={ProductSpec.SecondSpec}
              onChange={(e) =>
                setProductSpec({ ...ProductSpec, SecondSpec: e.target.value })
              }
              style={{ width: "150px", margin: "0 4px" }}
            />
          </Form.Item>

          <Form.Item label="* وصف المنتج" style={{ flexBasis: "100%" }}>
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexBasis: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexBasis: "100%",
              }}
            >
              <Button
                onClick={() =>
                  setTimeout(() => {
                    document
                      .querySelector(".ant-form-item-has-error")
                      ?.scrollIntoView(false);
                  }, 50)
                }
                loading={btnLoading}
                htmlType="submit"
                style={{ border: "none", color: "#fff" }}
              >
                {" "}
                save{" "}
              </Button>
              <Button
                onClick={() =>
                  setTimeout(() => {
                    document
                      .querySelector(".ant-form-item-has-error")
                      ?.scrollIntoView(false);
                  }, 50)
                }
                loading={btnLoading}
                htmlType="submit"
                style={{ background: "#fff", color: "#fff" }}
              >
                {" "}
                + منتج جديد{" "}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
