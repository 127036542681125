import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  message,
  Select,
  Modal,
} from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import Input from "antd/lib/input/Input";
import { CSVLink } from "react-csv";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { StyleToPRint } from "../../../Component/jsonsStyles/styleJson";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";
const { Option } = Select;
const { confirm } = Modal;

export const Chauffeurs = () => {
  const history = useHistory();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  const handelDltRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} السائق?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        Api.get(`Delivery/DeleteDelivery?DeliveryId=${Id}`).then(
          (res) => {
            if (res?.status === 200) {
              fetchLeedsList();
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handelEditRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} ${t.Driver}?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        Api.get(`Delivery/DeleteDelivery?DeliveryId=${Id}`).then(
          (res) => {
            if (res?.status === 200) {
              setLeadsList(res?.data?.model);
              setLeadsListFiltered(res?.data?.model);
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.name}</p>,
      dataIndex: currentLang === "en" ? "Name" : "NameLT",
    },
    {
      title: <p className="table-header-text "> {t.Phonenumber}</p>,
      dataIndex: "user_phone",
      // className:  "ltr-direction",
      render: (_, record) =>
        record?.AspNetUsers && (
          <a
            href={`tel:${
              record?.AspNetUsers?.PhoneKey + record?.AspNetUsers?.PhoneNumber
            }`}
          >
            {record?.AspNetUsers?.PhoneKey + record?.AspNetUsers?.PhoneNumber}{" "}
          </a>
        ),
    },
    // {
    //   title: <p className='table-header-text'> National Id Card <span> بطاقة الهوية الوطنية</span></p>,
    //   render: (_, record) => <Image src={record?.AspNetUsers?.FullName} />,
    // },
    {
      title: <p className="table-header-text"> {t.VehicleLicence}</p>,
      render: (_, record) => <Image src={record?.VehicleLicenceImage} />,
    },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="select-item-action">
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push({ pathname: "/add-chauffeur", state: record });
                // handelEditRowData(record?.Id);
              }}
              style={{ marginInlineEnd: 20, fontSize: 20, cursor: "pointer" }}
            />
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handelDltRowData(record?.Id);
              }}
              style={{
                marginInlineEnd: 20,
                color: "red",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const pdfRef = React.createRef();
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadsListFiltered, setLeadsListFiltered] = useState([]);
  const [TotalCount, setTotalCount] = useState(30);

  const fetchLeedsList = (index = 0) => {
    setLoading(true);
    // let body = {
    //   pagination_number: pageSize ? pageSize : defaultCurrentVal
    // }
    // Api.post(url ? url : 'leads', body).then(res => {
    //   // if (res?.data?.validation?.length > 0) {
    //   //   message?.error(res?.data?.validation[0])
    //   // }
    //   if (res?.data?.code === 200) {
    //     setLeadsList(res?.data?.data?.leads);
    //     setLeadsListFiltered(res?.data?.data);
    //   }
    //   setLoading(false)
    // }, (err) => {
    //   console.log("sdfasdafsdf", err)
    //   setLoading(false);
    // })

    Api.get(`Delivery/GetCompanyDeliveries?Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));
          setLeadsList(dataSource);
          setLeadsListFiltered(dataSource);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchLeedsList();
  }, [currentLang]);

  const handleChangeUSerGroup = (record, value) => {
    let body = {
      groupId: value,
      userId: record.user_id,
    };
    Api.post(`groups/assign`, body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message);
        fetchLeedsList();
      }
    });
  };

  const handleChangeStatus = (record, value) => {
    let body = {
      leadStatusId: value,
      userId: record.user_id,
    };
    Api.post(`leads/change/status`, body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message);
        fetchLeedsList();
      }
    });
  };

  const handleFilterList = (value) => {
    if (!!value) {
      let salesFiltered = leadsListFiltered?.leads?.filter((i) =>
        i.user_name.toLowerCase().includes(value.toLowerCase())
      );
      setLeadsList(salesFiltered);
    } else {
      fetchLeedsList();
    }
  };

  useEffect(() => {
    // const data = leadsList?.map(item => {
    //   let obj = {};
    //   tableColumn.map(dataIndex => {
    //     if (dataIndex.dataIndex === 'user_groups')
    //       return obj['User Group Status'] = item.user_groups.find(i => i.selected === 1)?.name;
    //     if (dataIndex.dataIndex === 'lead_status')
    //       return obj['Lead Status'] = item.lead_status.find(i => i.selected === 1)?.name;
    //     else
    //       return obj[dataIndex.title.props.children[0]?.toUpperCase()] = item[dataIndex.dataIndex];
    //   })
    //   return obj
    // });
    // const PdfTableColumn = []
    // pdfColumns.map((item) => {
    //   const sameColumn = tableColumn.find(i => i.dataIndex === item.dataIndex)
    //   if (sameColumn) {
    //     if (item.dataIndex === 'user_groups') {
    //       PdfTableColumn.push({
    //         title: <p className='table-header-text'> User group <span> شريحة المشتركين </span></p>,
    //         dataIndex: 'user_groups',
    //       })
    //     } else if (item.dataIndex === 'lead_status') {
    //       PdfTableColumn.push({
    //         title: <p className='table-header-text'> Status  <span> الحالة </span></p>,
    //         dataIndex: 'lead_status',
    //       })
    //     } else
    //       PdfTableColumn.push(sameColumn)
    //   }
    // })
    // const dataPdf = leadsList?.map(item => {
    //   let obj = {};
    //   tableColumn.map(dataIndex => {
    //     if (dataIndex.dataIndex === 'user_groups')
    //       return obj['user_groups'] = item.user_groups.find(i => i.selected === 1)?.name;
    //     if (dataIndex.dataIndex === 'lead_status')
    //       return obj['lead_status'] = item.lead_status.find(i => i.selected === 1)?.name;
    //     else
    //       return obj[dataIndex.dataIndex] = item[dataIndex.dataIndex];
    //   })
    //   return obj
    // });
    // setExcelData(data);
    // setPdfData(dataPdf)
    // setPdfTableColumns(PdfTableColumn);
  }, []);

  const handelPrint = () => {
    var bodyPrint = document.getElementById("print-section").innerHTML;
    var mywindow = window.open("", "PRINT");
    mywindow.document.write("<html><head>");
    mywindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">'
    );
    mywindow.document.write(`<style> ${StyleToPRint}</style>`);
    mywindow.document.write(bodyPrint);
    mywindow.document.write("</body></html>");
    mywindow.document.title = "Leads";
    setTimeout(() => {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }, 200);
  };

  return (
    <div>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p style={{ fontWeight: "bold" }}> {t.Chauffeurs}</p>

            <div className="product__btn">
              <Button
                onClick={() => history.push("/add-chauffeur")}
                className="primary"
              >
                {" "}
                {t.NewChauffeur}
              </Button>
            </div>
            {/* <div className='download__list'>
            <CSVLink filename="leads.csv" data={excelData}>Excel</CSVLink>
            <a onClick={handelPrint}>Pdf</a>
            <Dropdown trigger={'click'} overlay={columnList}>
              <a style={{ width: '135px' }}>
                column visibility <DownOutlined style={{ marginLeft: '7px' }} />
              </a>
            </Dropdown>
          </div>
          <div className='search__list--input'>
            <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
          </div> */}
          </div>
        }
        columns={columns}
        dataSource={leadsList || []}
        defaultCurrent={10}
        loading={loading}
        total={TotalCount}
        onChange={(pagination) => {
          fetchLeedsList(pagination?.current - 1);
        }}
        pageSize={30}
      />
    </div>
  );
};
