import React, { useEffect, useState } from "react";
import { Button, Select, Form, message, Input, Upload, Divider } from "antd";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UploadOutlined } from "@ant-design/icons";

import PhoneInput, {
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
const { Option } = Select;

export const AddChauffeur = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [categoryList, setCategoryList] = useState([]);
  const [ProfileImg, setProfileImg] = useState(null);
  const [ProfileImgFile, setProfileImgFile] = useState(null);
  const [VehicleImgFile, setVehicleImgFile] = useState(null);
  const [VehicleImg, setVehicleImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const supplierInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { currentUser } = useSelector((state) => state?.user);
  const { VehicleTypes } = useSelector((state) => state?.user?.metaData);
  const location = useLocation();

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  const fetchCategoriesList = () => {
    let body = {
      pagination_number: 100,
    };
    Api.post("categories/unassigned", body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setCategoryList(res.data.data.unAssignedCategories);
      }
    });
  };

  useEffect(() => {
    // fetchCategoriesList();
    if (location?.state) {
      // setVehicleImg(location?.state?.VehicleLicenceImage || null);
      // setProfileImg(location?.state?.AspNetUsers?.ProfileImage || null);
      if (location?.state?.AspNetUsers?.ProfileImage) {
        const mainImg = {
          uid: "-1",
          name: location?.state?.AspNetUsers?.FullName,
          status: "done",
          url: location?.state?.AspNetUsers?.ProfileImage,
          thumbUrl: location?.state?.AspNetUsers?.ProfileImage,
          type: "main",
        };
        setProfileImgFile([mainImg]);
      }
      if (location?.state?.VehicleLicenceImage) {
        const mainImg = {
          uid: "-2",
          name: t.VehicleLicence,
          status: "done",
          url: location?.state?.VehicleLicenceImage,
          thumbUrl: location?.state?.VehicleLicenceImage,
          type: "main",
        };
        setVehicleImgFile([mainImg]);
      }
    }
  }, []);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var base64result = reader.result.substr(reader.result.indexOf(",") + 1);
      setProfileImg(base64result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function getBase64VehicleLicenceImage(file) {
    // console.log(file, "filefilefile")
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var base64result = reader.result.substr(reader.result.indexOf(",") + 1);
      setVehicleImg(base64result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  //  function  getBase64(file){
  //    return new Promise(() => {
  //      const reader = new FileReader();
  //      reader.readAsDataURL(file);
  //      reader.onload = () => {
  //        const img = document.createElement('img');
  //        img.src = reader.result;
  //        var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
  //        console.log(base64result, "base64result")
  //        // this.setState({avatar_img : reader.result})

  //       };
  //     });
  //   }

  const onFinish = (values) => {
    // return false
    setLoading(true);
    const phoneNumber = parsePhoneNumber(values.PhoneNumber || "");

    let body = {
      Name: values?.Name,
      NameLT: values?.NameLT,
      Description: values?.Description,
      DescriptionLT: values?.DescriptionLT,
      ProfileImage: ProfileImg || null,
      PhoneKey: "+" + phoneNumber?.countryCallingCode,
      PhoneNumber: phoneNumber?.nationalNumber,
      Password: values?.password,
      ConfirmPassword: values?.ConfirmPassword,
      Email: values?.Email,
      DrivingLicence: values?.DrivingLicence,
      NationalIdCard: values?.NationalIdCard,
      VehicleTypeId: values?.VehicleTypeId || 1,
      CompanyId: currentUser?.Id || 1,
      VehicleLicenceImage: VehicleImg || null,
    };

    if (location?.state) {
      body.Id = location?.state?.Id;
      Api.post("Delivery/EditDelivery", body).then(
        (res) => {
          // message.success(res?.data?.model?.AspNetUsers);
          setLoading(false);

          if (res?.Code) {
            message.error(res?.message);
          }
          if (res?.status === 200) {
            history.push({
              pathname: "/Chauffeurs",
            });
          }
          if (res?.status === 400) {
            message.error(res?.errors?.message);
          }
        },
        (err) => {
          setLoading(false);
          message.error(err?.message);
        }
      );
      return false;
    }

    Api.post("Delivery/CreateDelivery", body).then(
      (res) => {
        // message.success(res?.data?.model?.AspNetUsers);
        setLoading(false);

        if (res?.Code) {
          message.error(res?.message);
        }
        if (res?.status === 200) {
          history.push({
            pathname: "/Chauffeurs",
          });
        }
        if (res?.status === 400) {
          message.error(res?.errors?.message);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err?.message);
        message.error(err?.message);
      }
    );
  };

  const handleChange = ({ fileList }) => {
    setProfileImgFile(fileList);
  };
  const handleChangeVehicle = ({ fileList }) => setVehicleImgFile(fileList);

  return (
    <div className="content__wrapper">
      {/* <div className='assign__cate--header'>
                <p style={{ fontWeight: 'bold' }} className='inter'>You have: {categoryList?.length} categories</p>
            </div> */}
      <div className="product__wrapper--add">
        {location?.state ? (
          <p className="product__title--page">{t.UpdateChauffeur} </p>
        ) : (
          <p className="product__title--page">{t.NewChauffeur} </p>
        )}
        <Form
          initialValues={{
            Name: location?.state?.Name,
            NameLT: location?.state?.NameLT,
            Description: location?.state?.Description,
            DescriptionLT: location?.state?.DescriptionLT,
            PhoneNumber:
              location?.state?.AspNetUsers?.PhoneKey +
              location?.state?.AspNetUsers?.PhoneNumber,
            Email: location?.state?.AspNetUsers?.Email,
            DrivingLicence: location?.state?.DrivingLicence,
            NationalIdCard: location?.state?.NationalIdCard,
            VehicleTypeId: location?.state?.VehicleTypeId || 1,
            CompanyId: currentUser?.Id || 1,
          }}
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={t.driverpicture}
            name="ProfileImage"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
            className="full-width"
          >
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              className="upload-list-inline"
              maxCount={1}
              beforeUpload={(file) => getBase64(file)}
              fileList={ProfileImgFile}
              onChange={handleChange}
            >
              <Button icon={<UploadOutlined />}>{t.Upload}</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label={t.drivernameAr}
            name="NameLT"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t.drivernameEn}
            name="Name"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t.descriptionAr}
            name="DescriptionLT"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t.descriptionEn}
            name="Description"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="PhoneNumber"
            label={t.Phonenumber}
            rules={[
              {
                validator(_, value) {
                  let phoneValid = isValidPhoneNumber(value || "");
                  if (!value) return Promise.reject("من فضلك ادخل رقم الجوال");
                  else if (!phoneValid)
                    return Promise.reject("رقم الجوال غير صحيح");
                  else return Promise.resolve();
                },
              },
            ]}
            className="input-holder"
          >
            <PhoneInput
              international
              countryCallingCodeEditable={false}
              defaultCountry="AE"
            />
          </Form.Item>

          <Form.Item
            name="Email"
            label={t.EmailAddress}
            rules={[
              {
                required: true,
                message: `${t.Pleaseinput} ${t.EmailAddress}`,
              },
              { type: "email", message: `${t.Emailvalid}` },
            ]}
            className="input-holder"
          >
            <Input
              type="email"
              // placeholder={t.EmailAddress}
            />
          </Form.Item>
          {!location?.state && (
            <Form.Item
              name="password"
              className="input-holder"
              label={t.Password}
              rules={[
                {
                  required: true,
                  message: `${t.Pleaseinput} ${t.Password}`,
                },
                {
                  min: 8,
                  message: `${t.Password8characters}`,
                },
                {
                  max: 20,
                  message: `${t.Password20characters}`,
                },
              ]}
            >
              <Input.Password
              // placeholder={t.Password}
              />
            </Form.Item>
          )}
          {!location?.state && (
            <Form.Item
              name="ConfirmPassword"
              dependencies={["password"]}
              className="input-holder"
              label={t.ReenterPassword}
              rules={[
                {
                  required: true,
                  message: `${t.Pleaseinput} ${t.ReenterPassword}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      // eslint-disable-next-line no-undef
                      return Promise.resolve();
                    }

                    // eslint-disable-next-line no-undef
                    return Promise.reject(new Error(`${t.Thetwopasswords}`));
                  },
                }),
              ]}
            >
              <Input.Password
              // placeholder={t.ReenterPassword}
              />
            </Form.Item>
          )}

          <Divider />

          <Form.Item
            label={t.VehicleLicence}
            name="VehicleLicenceImage"
            rules={[
              { required: !!!location?.state, message: t.ThisFieldisrequired },
            ]}
            // className="full-width"
          >
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              className="upload-list-inline"
              maxCount={1}
              beforeUpload={(file) => getBase64VehicleLicenceImage(file)}
              fileList={VehicleImgFile}
              onChange={handleChangeVehicle}
            >
              <Button icon={<UploadOutlined />}>{t.Upload}</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label={t.VehicleType}
            name="VehicleTypeId"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Select>
              {VehicleTypes.map((vehicle) => (
                <Option key={vehicle.Id} value={vehicle.Id}>
                  {vehicle.NameLT}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t.NationalId}
            name="NationalIdCard"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            label={t.DrivingLicence}
            name="DrivingLicence"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexBasis: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexBasis: "100%",
              }}
            >
              <Button
                loading={loading}
                htmlType="submit"
                style={{ border: "none", color: "#fff" }}
              >
                {" "}
                {t.Save}{" "}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
