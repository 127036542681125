import "./antd.css";
import "./style.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Routes from "./Routes";
import { ConfigProvider } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { SideBar } from "./pages/Layout/SideBar";
import { MainHeader } from "./pages/Layout/Header";
import login from "./pages/Login/login";
import { createBrowserHistory } from "history";
import { onMessageListener, requestForToken } from "./firebase";
import Api from "./Network/ApiConfig";
import { setNotificationList } from "./redux/user/user.action";
import moment from "moment";
import "moment/locale/ar"; // without this line it didn't work
import dayjs from "dayjs";
import uts from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

const { Content } = Layout;
const history = createBrowserHistory();
const token = localStorage.getItem("token");

dayjs.extend(duration);
dayjs.extend(uts);
class App extends Component {
  componentDidMount() {
    if (this.props.currentLang === "ar") moment.locale("ar");
    else moment.locale("en");
    this.handelAddNotification();
    // GetAllNotification((res) => {
    //   this.props.dispatch(setNotificationList(res?.data?.model));
    //   // audioTune.play();
    //   }, (err) => {})
    onMessageListener((payload) => {
      // console.log(payload, "sadfsdfsdfsd")
      Api.get("Users/GetAllNotification?Page=0").then(
        (res) => {
          // dispatch(setNotificationList(res?.data?.model));
          this.props.dispatch(setNotificationList(res?.data?.model || []));
        },
        (err) => {
          console.log(err);
        }
      );
      // console.log(audioTune)
      // GetAllNotification((res) => {
      // this.props.dispatch(setNotificationList(res?.data?.model));
      // // audioTune.play();
      // }, (err) => {})
    });
    document
      .getElementsByTagName("html")[0]
      .setAttribute("lang", this.props?.currentLang);
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentLang } = this.props;
    if (currentLang !== prevProps?.currentLang) {
      if (this.props.currentLang === "ar") moment.locale("ar");
      else moment.locale("en");
    }
  }

  handelAddNotification = async () => {
    const userToken = await requestForToken();
    // console.log(userToken, "userToken")
    let body = {
      Token: userToken,
      OS: "WEB",
    };
    Api.post("Users/AddPushToken", body).then(
      (res) => {
        Api.get("Users/GetAllNotification?Page=0").then(
          (res) => {
            // dispatch(setNotificationList(res?.data?.model));
            this.props.dispatch(setNotificationList(res?.data?.model || []));
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        // message.error(err?.message);
      }
    );
  };

  render() {
    const { currentLang } = this.props;
    return (
      <div className="app">
        <ConfigProvider direction={currentLang === "en" ? "ltr" : "ltr"}>
          <Router history={history}>
            <Switch>
              {token ? (
                <Layout>
                  <SideBar />
                  <Layout className="site-layout">
                    <MainHeader />
                    <Content
                      className="site-layout-background"
                      style={{
                        padding: 24,
                      }}
                    >
                      <Switch>
                        <Routes />
                      </Switch>
                    </Content>
                  </Layout>
                </Layout>
              ) : (
                <>
                  <Route exact path="/login" component={login} />
                  <Redirect to={"/login"} />
                </>
              )}
            </Switch>
          </Router>
        </ConfigProvider>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(App);
