import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Modal,
  Rate,
  Select,
  Tag,
  Tooltip,
} from "antd";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../Network/ApiConfig";
import {
  DownOutlined,
  EditOutlined,
  FormOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { FaMapMarkedAlt, FaUserTie } from "react-icons/fa";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setMetaData } from "../../redux/user/user.action";
import en from "../../localization/en";
import ar from "../../localization/ar";
import dayjs from "dayjs";

const { confirm } = Modal;

const { Option } = Select;
const { TextArea } = Input;
export const Order = () => {
  const [ordersList, setOrderList] = useState([]);
  const [captainList, setCaptainList] = useState([]);
  const [ordersListPaginate, setOrdersListPaginate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ActiveOrder, setActiveOrder] = useState(false);
  const [assignloading, setassignloading] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [cancelvalue, setCancelvalue] = useState(null);
  const dispatch = useDispatch();
  const { metaData } = useSelector((state) => state?.user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [TotalCount, setTotalCount] = useState(30);
  const [form] = Form.useForm();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const showModal = () => {
    setIsModalVisible(true);
    fetchCaptainList();
  };

  const handleCancel = () => {
    setActiveOrder(null);
    setIsModalVisible(false);
  };
  const fetchOrderList = (index = 0) => {
    // let body = {
    //   pagination_number: pagSize ? pagSize : defaultCurrentVal
    // }
    setLoading(true);
    Api.get(`Companies/GetCompanyOrders?OrderStatusId=0&Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          setOrderList(res?.data?.model);
          // setOrdersListPaginate(res.data.data)
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const fetchCaptainList = () => {
    Api.get("Delivery/GetCompanyDeliveries").then(
      (res) => {
        if (res?.status === 200) {
          setCaptainList(res?.data?.model);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchOrderList();
    // handleGetStatusOrder();

    Api.get("MetaData/GetMetaData").then(
      (res) => {
        dispatch(setMetaData(res?.data?.model));
      },
      (err) => {
        // console.log(err, "errrrrrrrrrrrrrrrrrrrrrrrrrrr")
      }
    );
  }, [currentLang]);

  const handleShowOrderDetails = (order) => {
    Api.get(`orders/show?orderId=${order.Id}`).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        history.push({
          pathname: "/ordersDetails",
          state: res.data.data.order_details,
        });
      }
    });
  };

  const handleGetStatusOrder = (order) => {
    // Api.get(`orders/orderStatus`).then((res) => {
    //   if (res.data.validation.length > 0) {
    //     message.error(res.data.validation[0]);
    //   }
    //   if (res.data.code === 200) {
    //     setOrderStatus(res.data.data.orderStatus);
    //   }
    // });
  };
  const handleCancelOrder = (id) => {
    confirm({
      title: `${t.DoyouWanttocancelthis} ${t.Order}?`,
      content: (
        <div className="cancel-model-holder">
          <Form form={form}>
            <Form.Item name="cancelation reason" rules={[{ required: true }]}>
              <TextArea
                value={cancelvalue}
                placeholder={t.WriteCancelationReason}
                autoSize={{ minRows: 8, maxRows: 15 }}
              />
            </Form.Item>
          </Form>
        </div>
      ),
      icon: <ExclamationCircleOutlined />,
      destroy: () => {
        form.resetFields();
      },
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        const cancelFormVal = form.getFieldValue("cancelation reason");
        form.resetFields();
        Api.get(
          `Companies/UpdateOrderStatus?OrderId=${id}&OrderStatusId=5&CancelationReason=${cancelFormVal}`
        ).then(
          (res) => {
            if (res?.status === 200) {
              fetchOrderList();
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {
        form.resetFields();
      },
    });
  };

  const handleChangeOrderStatus = (id, rec) => {
    Api.get(
      `Orders/UpdateOrderStatus?OrderId=${rec?.Id}&OrderStatusId=${id}`
    ).then(
      (res) => {
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);
          // fetchOrderList();
        }
      },
      (err) => {
        message.error(err?.message);
      }
    );
  };

  const handlAssignDelivery = (values) => {
    setassignloading(true);
    Api.get(
      `Companies/AssigneDeliveryToOrder?DeliveryId=${values?.DeliveryId}&OrderId=${ActiveOrder?.Id}`
    ).then(
      (res) => {
        setassignloading(false);
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);
          setIsModalVisible(false);
          fetchOrderList();
        }
        if (res?.Code) {
          message.error(res?.message);
          setIsModalVisible(false);
          fetchOrderList();
        }
      },
      (err) => {
        setassignloading(false);
        message.error(err?.message);
      }
    );
  };

  const history = useHistory();

  const columns = [
    {
      title: <p className="table-header-text"> {t.OrderID}</p>,
      dataIndex: "id",
      render: (_, record) => (
        <a
          onClick={() => {
            history.push({
              pathname: `/ordersDetails/${record?.Id}`,
              state: record,
            });
          }}
        >
          {" "}
          #{record?.Id}
        </a>
      ),
    },
    {
      title: <p className="table-header-text"> {t.User}</p>,
      render: (_, record) => (
        <div className="col-name-holder">
          <span>{record?.Customer?.AspNetUsers?.FullName} </span>
          {record?.Customer?.TotalRate ? (
            <Rate allowHalf disabled value={record?.CustomerRate?.RateValue} />
          ) : null}{" "}
        </div>
      ),
    },
    {
      title: <p className="table-header-text"> {t.total}</p>,
      dataIndex: "Total",
      render: (_, record) => (
        <span>
          {record?.Total} {t.SAR}{" "}
        </span>
      ),
    },
    {
      title: <p className="table-header-text"> {t.Status}</p>,
      dataIndex: "order_status",
      render: (_, record) => {
        return (
          <div>
            <Tag
              className={`order__status--tag ${record?.OrderStatus?.Name?.split(
                " "
              )
                .join("")
                ?.toLowerCase()}`}
            >
              {" "}
              {currentLang === "en"
                ? record?.OrderStatus?.Name
                : record?.OrderStatus?.NameLT}
            </Tag>
          </div>
        );
      },
    },
    {
      title: <p className="table-header-text"> {t.datePlaced}</p>,
      dataIndex: "CreationDate",
      render: (_, record) => {
        return (
          // <p> {moment(record?.CreationDate)?.format('YYYY-MM-DD HH:mm:SS')} </p>
          <p>
            {dayjs.utc(record?.CreationDate).local().format("YYYY-MM-DD HH:mm")}
          </p>
        );
      },
    },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      dataIndex: "address",
      render: (_, record) => {
        return (
          <div className="order__actions">
            <span
              onClick={() => {
                history.push({
                  pathname: `/ordersDetails/${record?.Id}`,
                  state: record,
                });
              }}
            >
              {t.ViewDetails}
            </span>
            {record?.OrderStatus?.OrderStatusCategoryId === 2 && (
              <span
                className={`${
                  (record?.OrderStatusId === 3 ||
                    record?.OrderStatusId === 5) &&
                  " canceled"
                }`}
                style={{
                  cursor:
                    record?.order_status?.toLowerCase() === "delivered" ||
                    record?.OrderStatusId === 3 ||
                    record?.OrderStatusId === 5
                      ? "not-allowed"
                      : "pointer",
                }}
                onClick={() => {
                  if (
                    record?.order_status?.toLowerCase() === "delivered" ||
                    record?.order_status === "Is Cancelled"
                  )
                    return;
                  handleCancelOrder(record?.Id);
                }}
              >
                {t.CancelOrder}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: <p className="table-header-text"> {t.Driver}</p>,
      dataIndex: "Delivery",
      render: (_, record) => {
        return record?.OrderStatusId !== 5 ? (
          !record?.Delivery ? (
            record?.OrderStatusId === 3 ? null : (
              <div className="order__actions">
                <span
                  style={{ color: "#612166" }}
                  onClick={() => {
                    // handlAssignDelivery(record)
                    showModal();
                    setActiveOrder(record);
                  }}
                >
                  {t.AssignDriver}
                </span>
              </div>
            )
          ) : (
            <div className="delivery-col-holder">
              {record?.OrderStatus?.OrderStatusCategoryId !== 4 && (
                <Tooltip title="change Driver">
                  <FormOutlined
                    className="change-driver-icon"
                    onClick={() => {
                      // handlAssignDelivery(record)
                      showModal();
                      // console.log("record", record)
                      setActiveOrder(record);
                    }}
                  />
                </Tooltip>
              )}
              <p>
                {currentLang === "en"
                  ? record?.Delivery?.Name
                  : record?.Delivery?.NameLT}
              </p>
              <Avatar size={24} src={record?.Delivery?.Image} />
            </div>
          )
        ) : null;
      },
    },
  ];

  const handleFilterList = (value) => {
    if (!!value) {
      let salesFiltered = ordersListPaginate?.orders?.filter((i) =>
        i.user_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setOrderList(salesFiltered);
    } else {
      fetchOrderList();
    }
  };

  return (
    <div className="order__wrapper">
      <Modal
        title={t.AssignDriver}
        visible={isModalVisible}
        destroyOnClose
        footer={null}
        onCancel={handleCancel}
      >
        <div className="modal-order-holder">
          <Form
            initialValues={{
              DeliveryId:
                ActiveOrder?.DeliveryId && "" + ActiveOrder?.DeliveryId,
            }}
            name="delivery"
            onFinish={handlAssignDelivery}
          >
            <Form.Item
              label={t.DeliveryCaptainlist}
              name="DeliveryId"
              rules={[{ required: true, message: t.ThisFieldisrequired }]}
            >
              <Select size="large">
                {captainList.map((s) => (
                  <Option key={s.Id}>
                    <div className="delivery-col-holder delivery-col-modal">
                      <Avatar
                        style={{
                          color: "#fff",
                          backgroundColor: "#612166",
                        }}
                        size={24}
                        src={s?.Image || <FaUserTie style={{ padding: 2 }} />}
                      />
                      <p style={{ marginLeft: 6 }}>
                        {currentLang === "en" ? s?.Name : s?.NameLT}
                      </p>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <div className="modal-action-holder">
              <Button
                onClick={handleCancel}
                danger
                size="large"
                ghost
                style={{ marginInlineEnd: 16 }}
              >
                {" "}
                {t.Cancel}{" "}
              </Button>
              <Button
                loading={assignloading}
                htmlType="submit"
                type="primary"
                size="large"
                style={{ border: "none", color: "#fff" }}
              >
                {" "}
                {t.Assign}{" "}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <TableGrid
        title={
          <div className="table__filter">
            <p className="bold">{t.Orders}</p>
            <Button
              type="primary"
              shape="circle"
              icon={<RedoOutlined />}
              onClick={() => {
                fetchOrderList();
              }}
              size="large"
            />
            {/* <div className='table__filter--count'>
            <p>Show</p>
            <Select
              defaultValue={defaultCurrentVal}
              placeholder="Lead status"
              onChange={(value) => {
                setDefaultCurrentVal(value);
                fetchOrderList(null, value)
              }}
            >
              <Option value={10}> 10 </Option>
              <Option value={20}> 20 </Option>
              <Option value={30}> 30 </Option>
              <Option value={40}> 40 </Option>
            </Select>
            <span>entries</span>
          </div> */}
            {/* <div className='table__filter--count'>
            <p>Filter orders by: </p>
            <Select
              defaultValue={'all'}
              className='filter__by--order'
              onChange={(value) => {
                if (value === 'all') fetchOrderList();
                else if (value === 'Is Cancelled') {
                  let filterOrderList = ordersListPaginate?.orders?.filter(i => i.order_status === value);
                  setOrderList(filterOrderList);
                }
                else {
                  let filterOrderList = ordersListPaginate?.orders?.filter(i => i.order_status === orderStatus.find(i => i.id === value)?.name);
                  setOrderList(filterOrderList);
                }
              }}
            >
              <Option value={'all'}> All </Option>
              {orderStatus?.map((stat, idx) => (
                <Option key={idx} value={stat.Id}> {stat.name} </Option>
              ))}
            </Select>
          </div> */}
            {/* <div className='search__list--input'>
            <Input placeholder='Search For Orders' onChange={(e) => handleFilterList(e.target.value)} />
          </div> */}
          </div>
        }
        columns={columns}
        loading={loading}
        dataSource={ordersList}
        // defaultCurrent={0}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchOrderList(pagination?.current - 1);
        }}
        // pagination={{ pageSize: defaultCurrentVal, total: ordersListPaginate?.pagination?.total }}
        // total={ordersListPaginate?.pagination?.total}
        // onChange={(pagination) => {
        //   let url = `orders?page=${pagination?.current}`
        //   fetchOrderList(url)
        // }}
      />
    </div>
  );
};
