import React, { useEffect, useState } from 'react';
import { TableGrid } from '../../../Component/Table';
import Image from '../../../images/download.jpeg'
// import './style.scss'
import Api from '../../../Network/ApiConfig';

const columns = [
	{
		title: <p className='table-header-text'> # </p>,
		dataIndex: 'id',
	},
	{
		title: <p className='table-header-text'> Name <span>الاسم</span> </p>,
		dataIndex: 'user_name',
	},
	{
		title: <p className='table-header-text'> image <span> الصورة </span></p>,
		dataIndex: 'product_image',
		render: (_, record) => {
			return (
				<div>
					<img height="40px" src={record.product_image} alt='Product Image' />
				</div>
			);
		},
	},
	{
		title: <p className='table-header-text'> Phone  <span> الموبايل </span></p>,
		dataIndex: 'user_phone',
		render: (_, record) => <a>{record.user_phone}</a>,
	},
	{
		title: <p className='table-header-text'> Email <span> البريد الالكترون </span></p>,
		dataIndex: 'user_email',

	},
	{
		title: <p className='table-header-text'> Product <span> المنتج </span></p>,
		dataIndex: 'product',
	},
	{
		title: <p className='table-header-text'> Company Name <span> اسم الشركة </span></p>,
		dataIndex: 'company_name',
	},
	{
		title: <p className='table-header-text'> Quantity <span> الكمية </span></p>,
		dataIndex: 'quantity',
	},
	{
		title: <p className='table-header-text'> date <span> التاريخ </span></p>,
		dataIndex: 'date',
	},
];


export const ProductRfq = () => {
	const [productList, setProductList] = useState([]);

	const [loading, setLoading] = useState(false);

	const handleFetchProduct = (url) => {
		setLoading(true);
		let body = {
			pagination_number: 10
		}
		Api.post(url ? url : 'reports/product-rfq', body).then(res => {
			setProductList(res.data.data);
			setLoading(false)
		})
	}

	useEffect(() => handleFetchProduct(), [])

	return (
		<div>
			<TableGrid title={<p> product RFQ <span> طلب سعر المنتج </span></p>}
				columns={columns}
				dataSource={productList?.product_rfqs}
				loading={loading}
				pagination={{ pageSize: 10 }}
				defaultCurrent={10}
				total={productList?.pagination?.products_count}
				onChange={(pagination) => {
					let url = `products?page=${pagination?.current}`
					handleFetchProduct(url)
				}}
			/>
		</div>
	);
};
