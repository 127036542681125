import React, { useEffect, useState } from 'react';
import { Checkbox,   Dropdown, Input, message, Select, } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss'
import Api from '../../../Network/ApiConfig'
import moment from 'moment';
import { CSVLink } from 'react-csv'; 
import { DownOutlined } from '@ant-design/icons'
import { StyleToPRint } from '../../../Component/jsonsStyles/styleJson';

const CheckboxGroup = Checkbox.Group;


const columns = [
    {
        title: <p className='table-header-text'> # </p>,
        dataIndex: 'id',
    },
    {
        title: <p className='table-header-text'> Name  <span> الاسم </span></p>,
        dataIndex: 'user_name',
    },
    {
        title: <p className='table-header-text'> phone <span> الموبايل </span></p>,
        dataIndex: 'user_phone',
    },
    {
        title: <p className='table-header-text'> Email <span> البريد الالكتروني </span></p>,
        dataIndex: 'user_email',
    },
    {
        title: <p className='table-header-text'> Company Name <span> اسم الشركة </span></p>,
        dataIndex: 'company_name',
    },
    {
        title: <p className='table-header-text'> Date <span> التاريخ </span></p>,
        dataIndex: 'date',
        render: (_, rec) => {
            return <p> {moment(rec?.date).format('DD/MM/YYYY')} </p>
        }
    },
];

export const CallBack = () => {
    const pdfRef = React.createRef()
    const [callBack, setCallBack] = useState([]);
    const [callBackList, setCallBackList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
    const [tableColumn, setTableColumn] = useState(columns);
    const [excelData, setExcelData] = useState([]);

    const [checkedList, setCheckedList] = useState(
        columns.map((item) => {
            return item.dataIndex
        }));
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(true);

    const checkBoxColumn = columns.map((item) => {
        return { label: item.title, value: item.dataIndex }
    })

    const onChange = list => {
        let tableColumnClone = [];
        columns.map((item) => {
            if (list.includes(item.dataIndex)) {
                tableColumnClone.push(item)
            }
        })

        setTableColumn(tableColumnClone)
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < checkBoxColumn.length);
        setCheckAll(list.length === checkBoxColumn.length);
    };

    const onCheckAllChange = e => {
        setTableColumn(e.target.checked ? columns : [])
        setCheckedList(e.target.checked ? columns.map((item) => {
            return item.dataIndex
        }) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const fetchSalesTeam = (url, pagSize) => {
        let body = {
            pagination_number: pagSize ? pagSize : defaultCurrentVal
        }

        setLoading(true);
        Api.post(url ? url : 'reports/call-back', body).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0])
            }
            if (res.data.code === 200) {
                setCallBack(res.data.data);
                setCallBackList(res.data.data?.call_backs);
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchSalesTeam()
    }, []);

    useEffect(() => {
        const data = callBack?.call_backs?.map(item => {
            let obj = {};
            tableColumn.map(dataIndex => {
                if (dataIndex.dataIndex === 'date')
                    return obj[dataIndex?.title?.props?.children[0]?.toUpperCase()] = moment(item[dataIndex.dataIndex]).format('DD/MM/YYYY');
                else
                    return obj[dataIndex.title.props.children[0]?.toUpperCase()] = item[dataIndex.dataIndex];
            })
            return obj
        });
        setExcelData(data);

    }, [tableColumn, callBack]);

    const columnList = () => {
        return (
            <div className='dropdown__column--visible'>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    Check all
                </Checkbox>
                <CheckboxGroup options={checkBoxColumn} value={checkedList} onChange={onChange} />
            </div>
        )
    }

    const handleFilterList = (value) => {
        if (!!value) {
            let salesFiltered = callBackList?.filter(i => i.user_name.toLowerCase().includes(value.toLowerCase()));
            setCallBack({
                ...callBack,
                call_backs: salesFiltered
            })
        } else {
            fetchSalesTeam()
        }
    }

    const handelPrint = () => {
        var bodyPrint = document.getElementById('print-section').innerHTML;
        var mywindow = window.open('', 'PRINT');
        mywindow.document.write('<html><head>');
        mywindow.document.write('<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">');
        mywindow.document.write(`<style> ${StyleToPRint}</style>`);
        mywindow.document.write(bodyPrint);
        mywindow.document.write('</body></html>');
        mywindow.document.title = 'Call Back';
        setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
            mywindow.print();
            mywindow.close();
        }, 200);
    }

    return (
        <div className='product__wrapper'>
            <TableGrid TableRef={pdfRef} title={
                <div className='table__filter'>
                    <p className='table__title'> Call Back <span> طلب الاتصال </span></p>
                    <div className='download__list'>
                        <CSVLink filename="call-back.csv" data={excelData || []}>Excel</CSVLink>
                        <a onClick={handelPrint} >Pdf</a>
                        <Dropdown trigger="click" overlay={columnList}>
                            <a style={{ width: '135px' }}>column visibility <DownOutlined style={{ marginLeft: '7px' }} /></a>
                        </Dropdown>
                    </div>
                    <div className='table__filter--count'>
                        <p>Show</p>
                        <Select
                            defaultValue={defaultCurrentVal}
                            placeholder="Lead status"
                            onChange={(value) => {
                                setDefaultCurrentVal(value);
                                fetchSalesTeam(null, value)
                            }}
                        >
                            <Select.Option value={10}> 10 </Select.Option>
                            <Select.Option value={20}> 20 </Select.Option>
                            <Select.Option value={30}> 30 </Select.Option>
                            <Select.Option value={40}> 40 </Select.Option>
                        </Select>
                        <span>entries</span>
                    </div>
                    <div className='search__list--input'>
                        <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
                    </div>
                </div>
            }
                columns={tableColumn}
                pagination={{ pageSize: defaultCurrentVal, total: callBack?.call_backs?.length }}
                dataSource={tableColumn.length > 0 ? callBack?.call_backs : []}
                loading={loading}
                defaultCurrent={defaultCurrentVal}
                pageSize={defaultCurrentVal}
                total={callBack?.pagination?.total}
                onChange={(pagination) => {
                    let url = `reports/call-back?page=${pagination?.current}`
                    fetchSalesTeam(url)
                }}
            />
        </div>
    );
};
