import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, message, Select } from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import Input from "antd/lib/input/Input";
import { CSVLink } from "react-csv";
import { DownOutlined } from "@ant-design/icons";
import { StyleToPRint } from "../../../Component/jsonsStyles/styleJson";

const { Option } = Select;

export const UserLeads = () => {
  const columns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "id",
    },
    {
      title: (
        <p className="table-header-text">
          {" "}
          name <span> الاسم </span>
        </p>
      ),
      dataIndex: "user_name",
    },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Phone <span> الموبايل </span>
        </p>
      ),
      dataIndex: "user_phone",
      render: (_, record) => <a>{record.user_phone} </a>,
    },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Email <span> البريد الالكتروني </span>
        </p>
      ),
      dataIndex: "user_email",
    },
    // {
    //   title: <p className='table-header-text'> Company Name <span> اسم الشركة </span></p>,
    //   dataIndex: 'company_name',
    // },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Status <span> الحالة </span>
        </p>
      ),
      dataIndex: "lead_status",
      render: (_, record) => (
        <p>{record?.lead_status?.find?.((i) => i.selected === 1)?.name}</p>
      ),
    },
    // {
    //   title: <p className='table-header-text'> User group <span> شريحة المشتركين </span></p>,
    //   dataIndex: 'user_groups',
    //   render: (_, record) => {
    //     return (
    //       <Select className='leeds__status selector-more-width'
    //         value={record?.user_groups?.find?.(i => i.selected === 1)?.id}
    //         placeholder="Assign User group"
    //         onChange={(value) => handleChangeUSerGroup(record, value)}
    //       >
    //         {record?.user_groups?.map?.((item) => (
    //           <Option value={item.id} key={item.id}>{item.name} </Option>
    //         ))}
    //       </Select>
    //     );
    //   },
    // },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Change status <span> تغيير الحالة </span>
        </p>
      ),
      dataIndex: "lead_status",
      render: (_, record) => {
        return (
          <Select
            className="selector-more-width leeds__status"
            value={record?.lead_status?.find?.((i) => i.selected === 1)?.id}
            placeholder="Change Lead status"
            onChange={(value) => handleChangeStatus(record, value)}
          >
            {record?.lead_status?.map?.((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];

  const pdfColumns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "id",
    },
    {
      title: (
        <p className="table-header-text">
          {" "}
          name <span> الاسم </span>
        </p>
      ),
      dataIndex: "user_name",
    },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Phone <span> الموبايل </span>
        </p>
      ),
      dataIndex: "user_phone",
      render: (_, record) => <a>{record.user_phone} </a>,
    },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Email <span> البريد الالكتروني </span>
        </p>
      ),
      dataIndex: "user_email",
    },
    // {
    //   title: <p className='table-header-text'> Company Name <span> اسم الشركة </span></p>,
    //   dataIndex: 'company_name',
    // },
    {
      title: (
        <p className="table-header-text">
          {" "}
          Status <span> الحالة </span>
        </p>
      ),
      dataIndex: "lead_status",
    },
    // {
    //   title: <p className='table-header-text'> User group <span> شريحة المشتركين </span></p>,
    //   dataIndex: 'user_groups',
    // },
  ];

  const pdfRef = React.createRef();
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [tableColumn, setTableColumn] = useState(columns);
  const [leadsListFiltered, setLeadsListFiltered] = useState([]);
  const [checkedList, setCheckedList] = useState(
    columns.map((item) => {
      return item.dataIndex;
    })
  );
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [pdfTableColumns, setPdfTableColumns] = useState(pdfColumns);
  const checkBoxColumn = columns.map((item) => {
    return { label: item.title, value: item.dataIndex };
  });

  const columnList = () => {
    return (
      <div className="dropdown__column--visible">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          Check all
        </Checkbox>
        <Checkbox.Group
          options={checkBoxColumn}
          value={checkedList}
          onChange={onChange}
        />
      </div>
    );
  };

  const onChange = (list) => {
    // if (list?.length === 0) return
    let tableColumnClone = [];
    columns.map((item) => {
      if (list.includes(item.dataIndex)) {
        tableColumnClone.push(item);
      }
    });

    setTableColumn(tableColumnClone);
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < checkBoxColumn.length);
    setCheckAll(list.length === checkBoxColumn.length);
  };

  const onCheckAllChange = (e) => {
    setTableColumn(e.target.checked ? columns : []);
    setCheckedList(
      e.target.checked
        ? columns.map((item) => {
            return item.dataIndex;
          })
        : []
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const fetchLeedsList = (url, pageSize) => {
    let body = {
      pagination_number: pageSize ? pageSize : defaultCurrentVal,
    };

    setLoading(true);
    Api.post(url ? url : "leads", body).then(
      (res) => {
        // if (res?.data?.validation?.length > 0) {
        //   message?.error(res?.data?.validation[0])
        // }
        if (res?.data?.code === 200) {
          setLeadsList(res?.data?.data?.leads);
          setLeadsListFiltered(res?.data?.data);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchLeedsList();
  }, []);

  const handleChangeUSerGroup = (record, value) => {
    let body = {
      groupId: value,
      userId: record.user_id,
    };
    Api.post(`groups/assign`, body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message);
        fetchLeedsList();
      }
    });
  };

  const handleChangeStatus = (record, value) => {
    let body = {
      leadStatusId: value,
      userId: record.user_id,
    };
    Api.post(`leads/change/status`, body).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        message.success(res.data.message);
        fetchLeedsList();
      }
    });
  };

  const handleFilterList = (value) => {
    if (!!value) {
      let salesFiltered = leadsListFiltered?.leads?.filter((i) =>
        i.user_name.toLowerCase().includes(value.toLowerCase())
      );
      setLeadsList(salesFiltered);
    } else {
      fetchLeedsList();
    }
  };

  useEffect(() => {
    const data = leadsList?.map((item) => {
      let obj = {};
      tableColumn.map((dataIndex) => {
        if (dataIndex.dataIndex === "user_groups")
          return (obj["User Group Status"] = item.user_groups.find(
            (i) => i.selected === 1
          )?.name);
        if (dataIndex.dataIndex === "lead_status")
          return (obj["Lead Status"] = item.lead_status.find(
            (i) => i.selected === 1
          )?.name);
        else
          return (obj[dataIndex.title.props.children[0]?.toUpperCase()] =
            item[dataIndex.dataIndex]);
      });
      return obj;
    });

    const PdfTableColumn = [];

    pdfColumns.map((item) => {
      const sameColumn = tableColumn.find(
        (i) => i.dataIndex === item.dataIndex
      );

      if (sameColumn) {
        if (item.dataIndex === "user_groups") {
          PdfTableColumn.push({
            title: (
              <p className="table-header-text">
                {" "}
                User group <span> شريحة المشتركين </span>
              </p>
            ),
            dataIndex: "user_groups",
          });
        } else if (item.dataIndex === "lead_status") {
          PdfTableColumn.push({
            title: (
              <p className="table-header-text">
                {" "}
                Status <span> الحالة </span>
              </p>
            ),
            dataIndex: "lead_status",
          });
        } else PdfTableColumn.push(sameColumn);
      }
    });

    const dataPdf = leadsList?.map((item) => {
      let obj = {};
      tableColumn.map((dataIndex) => {
        if (dataIndex.dataIndex === "user_groups")
          return (obj["user_groups"] = item.user_groups.find(
            (i) => i.selected === 1
          )?.name);
        if (dataIndex.dataIndex === "lead_status")
          return (obj["lead_status"] = item.lead_status.find(
            (i) => i.selected === 1
          )?.name);
        else return (obj[dataIndex.dataIndex] = item[dataIndex.dataIndex]);
      });
      return obj;
    });

    setExcelData(data);
    setPdfData(dataPdf);
    setPdfTableColumns(PdfTableColumn);
  }, [tableColumn, leadsList]);

  const handelPrint = () => {
    var bodyPrint = document.getElementById("print-section").innerHTML;
    var mywindow = window.open("", "PRINT");
    mywindow.document.write("<html><head>");
    mywindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">'
    );
    mywindow.document.write(`<style> ${StyleToPRint}</style>`);
    mywindow.document.write(bodyPrint);
    mywindow.document.write("</body></html>");
    mywindow.document.title = "Leads";
    setTimeout(() => {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }, 200);
  };

  return (
    <div>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p style={{ fontWeight: "bold" }}>
              {" "}
              Customers{" "}
              <span style={{ marginLeft: "10px", fontWeight: "500" }}>
                {" "}
                العملاء{" "}
              </span>
            </p>
            {/* <div className='download__list'>
            <CSVLink filename="leads.csv" data={excelData}>Excel</CSVLink>
            <a onClick={handelPrint}>Pdf</a>
            <Dropdown trigger={'click'} overlay={columnList}>
              <a style={{ width: '135px' }}>
                column visibility <DownOutlined style={{ marginLeft: '7px' }} />
              </a>
            </Dropdown>
          </div>
          <div className='search__list--input'>
            <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
          </div> */}
          </div>
        }
        columns={tableColumn}
        dataSource={tableColumn.length > 0 ? leadsList : []}
        pagination={{ pageSize: 10 }}
        defaultCurrent={10}
        loading={loading}
        total={leadsListFiltered?.pagination?.total}
        onChange={(pagination) => {
          let url = `leads?page=${pagination?.current}`;
          fetchLeedsList(url);
        }}
        pdfData={pdfData}
        pdfTableColumns={pdfTableColumns}
        pageSize={defaultCurrentVal}
      />
    </div>
  );
};
